<template>
    <div class="items-center gap-10 hidden lg:flex">
        <div class="bg-white shadow border rounded-3xl w-fit">
            <QrcodeVue
                class="px-4 h-32 w-32"
                :value="url"
                :size="300"
                :image-settings="imageSettings"
                level="M"
                render-as="svg"
            />
        </div>
        <span class="font-medium">
            See game updates in realtime on your phone by scanning the QR code or in a new tab by clicking
            <NuxtLink
                target="_blank"
                class="text-blue-500 hover:underline"
                :to="`temp-games/${tempGameStore?.tempGame?.id}`"
            >
                here
            </NuxtLink>
        </span>
    </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import QrcodeVue from 'qrcode.vue';

const tempGameStore = useTempGameStore();

const imageSettings = ref({
    src: 'https://arenachamp.com/icon.png',
    width: 100,
    height: 100,
    excavate: false,
});

const url = computed(() => {
    return `https://arenachamp.com/temp-games/${tempGameStore?.tempGame?.id}`;
});
</script>

<style lang="scss" scoped></style>
